$(document).ready(function() {
  let checkboxItem = $('.modificationRequest_checkItem input'),
    toggledContent = $('.modificationRequest_box, .modificationRequest_textarea'),
    modificationRequestId = document.getElementById('modificationRequest'),
    $checkboxItem = $('#modificationRequest .modificationRequest_checkItem input'),
    $inputs = $('#modificationRequestForm :input[type="text"], #modificationRequestForm :input[type="file"], #modificationRequestForm textarea')

  $(document.getElementById('modificationRequest')).on('shown.bs.modal', function (e) {
    prefillValues()
  })

  checkboxItem.on('change', function () {
    let chekedInputs = $('.modificationRequest_checkItem input:checked')
    $('#submitForm').prop('disabled', chekedInputs.length === 0)

    if (chekedInputs.length === 0) {
      toggledContent.hide()
    } else {
      toggledContent.hide()
      chekedInputs.each(function() {
        $(`.${$(this).data('content')}`).show()
      })
    }
  })

  function clearModal() {
    $inputs.val('')
    $checkboxItem.prop( 'checked', false )
    $('.modificationRequest_box, .modificationRequest_textarea').hide()
    window.$('#modificationRequest').modal('hide')
    $('#submitForm').prop('disabled', true)
    $('.fileAttach input').each(function() {
      $(this).siblings('.fileAttach_images').empty()
    })
  }

  function prefillValues() {
    let programId = $('#modification_request_program_id').val()
    let url = `/api/v2/storefront/self_served_programs/${programId}/modification_requests`

    $.ajax({
      cache: false,
      contentType: false,
      headers: {
        Authorization: SpreeAPI.prepareHeaders().Authorization},
      processData: false,
      success: function(result) {
        document.querySelector('[name="modification_request[program_story]"]').value = result.program_story

        for (i in result.product_images) {
          const id = Number(i)+1
          const holder = document.getElementById(`previewProductImage${id}`)
          const replaceWith= `
            <div class="fileAttach_item" data-signed-id="${result.product_images[i].signed_id}">
              <div class="fileAttach_image">
                <img src="${result.product_images[i].url}"
                     title="${result.product_images[i].filename}"
                     alt="${result.product_images[i].filename}"/>
              </div>
              <div class="fileAttach_details">
                <div class="fileAttach_name">
                  ${result.product_images[i].filename}
                </div>
              <button type="button" class="btn btn-link remove" data-remove-image>
                <span class="fas fa-trash"></span>
              </button>
              </div>
            </div>
        `

          $(replaceWith).prependTo(holder)
        }
      },
      error: function(res) {
        console.log('failed to prefill values')
      },
      type: 'GET',
      url: url,
    })

  }

  if ($('#modificationRequest').length > 0) {
    modificationRequestId.addEventListener('show.bs.modal', function (e) {
      let programId = $(e.relatedTarget).data('program-id')
      $('#modification_request_program_id').val(programId)
    })

    modificationRequestId.addEventListener('hidden.bs.modal', () => clearModal())
  }

  $('#modificationRequestForm').on('submit', function(e) {
    e.preventDefault()

    let programId = $('#modification_request_program_id').val()
    let url = `/api/v2/storefront/self_served_programs/${programId}/modification_requests`
    let formData = new FormData()

    $inputs.each(function() {
      if($(this).parents('.modificationRequest_box, .modificationRequest_textarea').is(':visible')) {
        formData.append(this.name, $(this).val())
      }
    })

    // set files inputs
    let brand_logo_input = e.target.modification_request_brand_logo
    if (brand_logo_input.files.length > 0) {
      formData.set('modification_request[brand_logo]', brand_logo_input.files[0])
    }

    let product_image_input_1 = document.getElementById('modification_request_product_images_1')
    let product_image_input_2 = document.getElementById('modification_request_product_images_2')

    if (formData.has('modification_request[product_images][]')) {
      formData.delete('modification_request[product_images][]')
    }
    if (product_image_input_1.files.length > 0) {
      formData.append('modification_request[product_images][]', product_image_input_1.files[0])
    }
    if (product_image_input_2.files.length > 0) {
      formData.append('modification_request[product_images][]', product_image_input_2.files[0])
    }

    $.ajax({
      cache: false,
      contentType: false,
      data: formData,
      headers: {
        Authorization: SpreeAPI.prepareHeaders().Authorization},
      processData: false,
      success: function(result) {
        clearModal()
        $.gritter.add({
          fade: true,
          text: result.message,
          time: '10000',
          title: 'Notification',
        })
      },
      error: function(res) {
        const { errors } = res.responseJSON;

        $.gritter.add({
          class_name: 'alert',
          fade: true,
          text: errors?.length ? errors : 'Failed to create a modification request!',
          time: '10000',
          title: 'Notification',
        })
      },
      type: 'POST',
      url: url,
    })

    return false
  })

})
