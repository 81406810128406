const authWithTerracycle = (options) => {
  let url = '/users/auth/terracycle'

  if (options && options.signUp) {
    url += '?authentication_form=sign_up'
  }
  const title = 'Auth with Terracycle'
  const width = 580
  const height = 480
  const screenX = typeof window.screenX !== 'undefined' ? window.screenX : window.screenLeft,
    screenY = typeof window.screenY !== 'undefined' ? window.screenY : window.screenTop,
    outerWidth = typeof window.outerWidth !== 'undefined' ? window.outerWidth : document.body.clientWidth,
    outerHeight = typeof window.outerHeight !== 'undefined' ? window.outerHeight : (document.body.clientHeight - 22),
    left = parseInt(screenX + ((outerWidth - width) / 2), 10),
    top = parseInt(screenY + ((outerHeight - height) / 2.5), 10),
    features = (`width=${width},height=${height},left=${left},top=${top}`)

  const popup = window.open(url, title, features)

  if (!popup || typeof(popup) === 'undefined') {
    console.log('Pop-up got blocked!')
  } else {
    popup.resizeBy(0, 50)

    // Listen to popup window.close() event
    const timer = setInterval(() => {
      if(popup.closed) {
        clearInterval(timer)

        const isCheckout = window.location.href.match(/checkout\/registration/)
        const isThanksPageRedirect = options && options.redirectUrl && options.signUp && localStorage.sucessAuth && !isCheckout

        if ((options && options.redirectUrl && !options.signUp) || isThanksPageRedirect) {
          localStorage.removeItem('sucessAuth')
          window.location = options.redirectUrl
        } else if (isCheckout) {
          window.location = `/${SPREE_LOCALE}/checkout/address`
        } else if (localStorage.redirectToRoot && JSON.parse(localStorage.redirectToRoot)) {
          localStorage.redirectToRoot = false
          window.location = `/${SPREE_LOCALE}/`
        } else {
          localStorage.redirectToRoot = true
          window.location.reload()
        }
      }
    }, 1000)
  }
}

window.authWithTerracycle = authWithTerracycle
