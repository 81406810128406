import $ from 'jquery'

const SkipCustomization = {
  allFieldsSkipped: true,
  generalContainer: '#general-information',
  generalLabels: null,
  imageContainer: '#images',
  imageLabels: null,
  init: function () {
    this.generalLabels = $('[data-type="general-information"]').find('[data-label]')
    this.imageLabels = $('[data-type="images"]').find('[data-label]')

    return this
  },
  render: function (labels, container) {
    $(container).empty()
    labels.map((key, label) => $(container).append(`<li>${$(label).attr('data-label')}</li>`))
  },
  skip: function () {
    if (this.allFieldsSkipped) {
      $('input[data-label]').map((key, label) => label.value = null)
    }
    $('#selfServedForm').submit()
  },
  skipAllFields: function() {
    this.render(this.generalLabels, this.generalContainer)
    this.render(this.imageLabels, this.imageContainer)
  },
  skippedFields: function() {
    let emptyGeneralFields = this.generalLabels.filter(function () { return this.value === '' }),
      emptyImageFields = this.imageLabels.filter(function () { return this.files.length === 0 &&
          $(this).siblings('.fileAttach_images').children().length === 0 })

    this.toggleContent(emptyGeneralFields, this.generalContainer)
    this.toggleContent(emptyImageFields, this.imageContainer)

    if (emptyGeneralFields.length > 0 || emptyImageFields.length > 0) {
      this.render(emptyGeneralFields, this.generalContainer)
      this.render(emptyImageFields, this.imageContainer)
      this.allFieldsSkipped = false
      window.$('#skipCustomization').modal('show')
    } else {
      $('#selfServedForm').submit()
    }
  },
  toggleContent: function (emptyFields, container) {
    emptyFields.length === 0 ? $(container).parent().hide() : $(container).parent().show()
  },
}

$(document).ready(function() {
  $('#selfServedForm [data-type="submit"]').click(function(e) {
    e.preventDefault()
    SkipCustomization.init().skippedFields()
  })

  $('#skipCustomizationButton').click(function(e) {
    e.preventDefault()
    SkipCustomization.init().skip()
  })

  if ($('.stepThird').length) {
    let skipCustomizationPopup = document.getElementById('skipCustomization')

    skipCustomizationPopup.addEventListener('hidden.bs.modal', function () {
      SkipCustomization.allFieldsSkipped = true
    })
  }
})

window.SkipCustomization = SkipCustomization
