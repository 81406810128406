import $ from 'jquery'

const handleInputChange = (e) => {
  const $e = $(e)
  const min = $e.attr('min'),
    max = $e.attr('max'),
    val = $e.val()

  $e.css('background-size', `${(val - min) * 100 / (max - min) }% 100%`)
}

$(document).ready(function() {
  let rangeInput = 'input[type="range"]'

  const handleRangeChange = (_this) => {
    let rangeInput_ = $(_this).parents('.range').find(rangeInput)

    rangeInput_.val(_this.value)
    handleInputChange(rangeInput_)
  }

  $(rangeInput).each(() => handleInputChange($(this)))

  $(rangeInput).on('input', () => handleInputChange($(this)))

  $(document).on('input', '.range-input', function() {
    $(this).siblings().find('input').val(this.value)
  })

  $(document).on('input', '.range-input-field-js', function() {
    parseInt(this.value, 10) > parseInt(this.max, 10) && (this.value = this.max)
    parseInt(this.value, 10) < parseInt(this.min, 10) && (this.value = this.min)
    if (isNaN(parseFloat(this.value))) {
      this.value = this.min
      handleRangeChange(this)
    }
    handleRangeChange(this)
  })

  $(document).on('keypress', '.range-input-field', function(event) {
    (event.which == 44 || event.which == 46) && event.preventDefault()
  })
})

window.handleInputChange = handleInputChange
