window.addEventListener('DOMContentLoaded', () => {
  if ($('.profile')[0]) {
    let activeLinkItem = $('.profileNav_activeItem').find('.profileNav_itemName'),
      activeSubLinksList = $('.profileNav_activeItem').find('.profileNav_subItemsList'),
      activeSubLinks = $('.profileNav_activeItem').find('.profileNav_subLink')

    //Set active link text for profile main links dropdown on mobile
    $('#mainLinksDropdown').text(activeLinkItem.text())

    //Set active subLink
    if (activeSubLinksList.length !== 0) {
      let activeSubLink = activeSubLinks.first(),
        hash = window.location.hash

      const setActiveLink = () => {
        if (hash) {
          activeSubLinks.each(function () {
            if ($(this).prop('hash') === hash) {
              activeSubLinks.removeClass('active')
              activeSubLink = $(this).addClass('active')
            }
          })
        }
      }

      setActiveLink()

      //Create subLinks dropdown
      $('.profileNav').append(
        `<div class="profileNav_subLinksDropdown" id="subLinksDropdown">
          <a id="subLinksDropdownTitle"
             class="profileNav_subLinksDropdownTitle"
             data-bs-toggle="collapse"
             href="#subLinkList"
             aria-expanded="false"
             aria-controls="subLinkList">${ activeSubLink.text() }</a>
          <ul class="profileNav_subLinkList collapse" id="subLinkList">${ activeSubLinksList.html() }</ul>
        </div>`
      )

      window.addEventListener('hashchange', function() {
        hash = window.location.hash
        setActiveLink()
        $('#subLinksDropdownTitle').text(activeSubLink.text())
      })

      //Close dropdown on click on the link or outside
      $(document).click(function(e) {
        if ($(window).width() < 991) {
          if ($(e.target).closest('.profileNav')) {
            $('.profileNav_subLinkList').collapse('hide')
          }
        }
      })

    }

    $('.profileNav_itemName').click(function (e) {
      if ($(window).width() > 991) {
        e.preventDefault()
      }

      $(this).parent().toggleClass('active')

      $(this).siblings('.profileNav_subItemsList').slideToggle()
      $(this).parent().siblings().children('.profileNav_subItemsList').slideUp()
      $(this).parent().siblings('.active').removeClass('active')
    })
  }
})
