import $ from 'jquery'
import 'select2'

$(document).ready(function() {
  $('.bootstrapForm_formGroup select:not(.stateSelect)').select2({
    minimumResultsForSearch: Infinity,
  })

  $('.bootstrapForm select:not(.stateSelect)').select2({
    minimumResultsForSearch: -1,
  })
})
