import $ from 'jquery'
import 'slick-carousel'

const $slickSlider = (selector, options = {}) => {
  const slider = $(`${selector}:not(.slick-initialized`),
    sl = slider.slick(options)

  $(window).on('resize', function() {
    if(!sl.hasClass('slick-initialized')) {
      slider.slick(options)
    }
  })
}

window.$slickSlider = $slickSlider
