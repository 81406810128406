import $ from 'jquery'

const removeDraft = {
  init: function (program) {
    this.id = program.id
    this.removeRequest(this.id)
  },
  removeRequest: function (programId) {
    $('[data-remove-draft]').on('click', function (e) {
      let formData = new FormData()
      formData.append('state', 'removed')
      formData.append('authenticity_token', $('meta[name=csrf-token]').attr('content'))

      $.ajax({
        cache: false,
        contentType: false,
        data: formData,
        headers: {
          Authorization: SpreeAPI.prepareHeaders().Authorization},
        processData: false,
        success: function() {
          window.location.reload()
        },
        type: 'POST',
        url: `/${SPREE_LOCALE}/self_served_programs/${programId}/change_state`,
      })
    })
  },
}

window.removeDraft = removeDraft
