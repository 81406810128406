const currency = SPREE_CURRENCY_SIGN

const ParamName = (data, param) => {
  return `self_served_program[self_served_program_variants_attributes][${data.variant_id}][${param}]`
}

const PriceFormatter = (value) => {
  const numb = Number(value).toFixed(2)
  return currency + Math.round(numb * 100) / 100
}

const ProgramHead = (data, t) => {
  return `
    <div class="basicProgram_row basicProgram_head">
      <h3>${data.size}</h3>
      <div class="row justify-content-between align-items-center">
        <div class="col-3 col-md-4">
          <img src="${data.image}" alt="${data.size}">
        </div>
        <div class="col-9 col-md-8 col-lg-7">
          <div class="basicProgram_unit">
            <span>${t.unit_dimensions}</span>
            <span><b>${data.unit}</b></span>
          </div>
          <div class="basicProgram_unit">
            <span>${t.unit_price}</span>
            <span><b>${currency}${data.price}</b></span>
          </div>
        </div>
      </div>
      <button type="button"
              class="tooltip-btn icon-tooltip"
              data-bs-toggle="tooltip"
              title='${data.description.replace(/'/g, '&#8216')}'>
      </button>
   </div>
  `
}

const ProgramSubsidy = (data, t) => {
  return `
    <div class="basicProgram_row basicProgram_subsidy">
      <input type="number" class="d-none" value="${data.variant_id}" name="${ParamName(data, 'variant_id')}">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <h4><b>${t.set_subsidy_title}:</b></h4>
          <div class="grey-text smallerText">
            <span>
              ${t.set_subsidy_description}
            </span>
            <button type="button"
              class="tooltip-btn icon-tooltip d-inline-block position-static"
              data-bs-toggle="tooltip"
              title='${t.set_subsidy_tooltip}'>
            </button>
          </div>
        </div>
        <div class="col-md-8 col-lg-7">
          <div class="range ${data.in_stock_flag ? 'disabled' : ''}" data-type="subsidy">
            <div class="range-left-side">
              <output id="customer-value-${data.variant_id}">
                ${PriceFormatter(data.price - data.subsidy)}
              </output>
              <span class="range-side-text">${t.cost_customers}</span>
            </div>
            <input type="range"
                   id="initial-subsidy-${data.variant_id}"
                   min="0"
                   max="${data.price}"
                   value="${data.price - data.subsidy}"
                   ${data.in_stock_flag ? 'disabled' : ''}
            />
            <div class="range-right-side">
              <input id="subsidy-${data.variant_id}"
                     class="d-none"
                     name="${ParamName(data, 'subsidy')}"
                     type="number"
                     value="${data.subsidy}"
                     readonly
              >
              <input class="range-input-field subsidy-input-field-js ${data.in_stock_flag ? 'is-disabled' : ''}"
                     id="subsidy-value-${data.variant_id}"
                     min="0"
                     max="${data.price}"
                     value="${PriceFormatter(data.subsidy)}"
                     ${data.in_stock_flag ? 'disabled' : ''}
               />
              <span class="range-side-text">${t.your_subsidy}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  `
}

const ProgramQuantity = (data, t) => {
  return `
    <div class="basicProgram_row basicProgram_quantity ">
      <div class="row justify-content-between">
        <div class="col-md-4">
          <h4><b>${t.set_quantity_title}:</b></h4>
          <div class="grey-text smallerText">${t.set_quantity_description}</div>
        </div>
        <div class="col-md-8 col-lg-7">
          <div class="range" data-type="quantity">
             <div class="range-left-side">
               <output id="initial-quantity-${data.variant_id}" class="is-disabled">
                 ${data.min_quantity}
               </output>
               <span class="range-side-text">${t.minimum_required_quantity}</span>
             </div>
             <input type="range"
                    class="range-input"
                    id="initial-quantity-${data.variant_id}"
                    name="${ParamName(data, 'initial_quantity')}"
                    step="1"
                    min="${data.min_quantity}"
                    max="${data.max_quantity}"
                    value="${data.quantity}"
             />
             <div class="range-right-side">
               <input type="text"
                      class="range-input-field range-input-field-js"
                      id="quantity-value-${data.variant_id}"
                      min="${data.min_quantity}"
                      max="${data.max_quantity}"
                      value="${data.quantity}"
                      maxlength="3"
                      pattern="\\d*"
               />
               <span class="range-side-text">${t.quantity}</span>
             </div>
          </div>
        </div>
      </div>
    </div>
  `
}

const ProgramLimit = (data, t) => {
  return `
    <div class="basicProgram_row">
      <div class="row justify-content-between" data-limit="${data.variant_id}">
        <div class="col-md-4">
          <h4><b>${t.set_limit_title}:</b></h4>
          <div class="grey-text smallerText mb-5 m-md-0">${t.set_limit_description}</div>
        </div>
        <div class="col-md-8 col-lg-7">
          <div class="purchaseLimit">
            <div class="grey-text smallerText">${t.why_change_limit}</div>
            <button type="button"
                    class="tooltip-btn icon-tooltip"
                    data-bs-toggle="tooltip"
                    title='${t.purchase_limit_tooltip}'>
            </button>
          </div>
          <div class="basicProgram_limit bootstrapForm">
            <label class="checkbox">
              <input type="checkbox" id="limit-checkbox-${data.variant_id}" ${data.limit === 1 ? 'checked' : ''}>
              <label for="limit-checkbox">${t.purchase_limit}</label>
            </label>
          </div>
          <div class="basicProgram_increaseLimit bootstrapForm">
            <div class="form-group">
              <label for="limit-${data.variant_id}">${t.increase_limit}:</label>
              <input type="number"
                     id="limit-${data.variant_id}"
                     class="required form-control"
                     name="${ParamName(data, 'purchase_limit')}"
                     min="1"
                     value="${data.limit}"
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  `
}

const ProgramSubtotal = (data, t) => {
  return `
    <div class="basicProgram_subtotal">
      <div class="row justify-content-between">
        <div class="col-4">
          <h4>${t.subtotal}:</h4>
        </div>
        <div class="col-8 col-lg-7 text-end">
          <h4 id="subtotal-${data.variant_id}">${currency}${data.quantity * data.subsidy}</h4>
        </div>
      </div>
    </div>
  `
}

const CartTotal = (programs, total, t) => {
  return `
    <div class="cartTotal">
      <ul class="cartTotal_list">
        ${programs.map((el) => CartTotalItem(el)).join('')}
      </ul>
      <div class="cartTotal_fee">
        <div class="cartTotal_fee-label">
          ${t.monthly_subscription_fee}
        </div>
        <div class="cartTotal_fee-value">
           ${t.subscription_fee_with_currency}
        </div>
      </div>
      <div class="cartTotal_sum">
        <h4 class="mb-0"><b>${t.cart_total}</b></h4>
        <h3 class="mb-0">${PriceFormatter(total)}</h3>
      </div>
    </div>
  `
}

const CartTotalItem = (el) => {
  return `
    <li>
      <span>${el.size}</span>
      <span>${el.quantity} <i>X</i> ${PriceFormatter(el.subsidy)}</span>
    </li>
  `
}

const OrderDetails = {
  currentProgram: null,
  defaultState: true,
  selectedPrograms: [],
  t: null,
  total: null,
  // eslint-disable-next-line sort-keys
  append(html) {
    $(this.currentProgram).find('.basicProgram').append(html)
  },
  changeCardTotal(input, data) {
    const elementIndex = this.selectedPrograms.findIndex((obj) => obj.variant_id === data.variant_id)

    if ($(input).closest('[data-type="subsidy"]').length) {
      this.selectedPrograms[elementIndex].subsidy = data.price - input.value
    } else {
      this.selectedPrograms[elementIndex].quantity = input.value
    }

    this.setCartTotal()
  },
  checkState() {
    if (this.selectedPrograms.length !== 0) {
      $('#order-details-header, #process-to-checkout').show()
      $('#set-details-btn').hide()
      setTimeout(() => $('#cart-total').show())
    } else {
      $('#order-details-header, #cart-total, #process-to-checkout').hide()
      $('#set-details-btn').show()
      $('.totalBudget_rest').empty()
    }
  },
  checkStatus(data) {
    if (data.checked) {
      this.selectedPrograms.push(data)
      this.render(data)
      $(document).ready(() => this.disableSubmit())
    }

    $(`#program-${data.variant_id}`).change(function () {
      const programItem = $(this).closest('[data-role="self_program_item"]')
      if ($(this).is(':checked')) {
        programItem.addClass('checked')
        OrderDetails.selectedPrograms.push(data)
        if (!OrderDetails.defaultState) {
          OrderDetails.init(`#basic-program-${data.variant_id}`, OrderDetails.t).render(data)
          OrderDetails.checkState()
        }
      } else {
        programItem.removeClass('checked')
        OrderDetails.destroy(data)
      }

      $('#set-details-btn').prop('disabled', OrderDetails.selectedPrograms.length === 0)
      OrderDetails.setCartTotal()

      if (OrderDetails.selectedPrograms.length === 0) {
        OrderDetails.disableSubmit()
        $('#budget').val('')
      }
    })
  },
  destroy(data) {
    this.selectedPrograms = this.selectedPrograms.filter((item) => data.variant_id !== item.variant_id)
    this.init(`#basic-program-${data.variant_id}`, this.t)
    $(this.currentProgram).empty()
    this.checkState()
  },
  disableSubmit() {
    const submitButtons = document.querySelectorAll('#selfServedForm button[data-type="submit"]')

    submitButtons.forEach((button) => (button.disabled = this.selectedPrograms.length === 0))
  },
  handleRangeChange(data) {
    $(`#basic-program-${data.variant_id}`).find('input[type="range"], .range-input-field.range-input-field-js').each(function () {
      handleInputChange(this)

      $(this).on('change', function () {
        const value = this.value
        const id = data.variant_id
        const price = data.price
        const rangeSubsidy = $(this).closest('[data-type="subsidy"]')

        rangeSubsidy.find(`#customer-value-${id}`).text(PriceFormatter(value))
        rangeSubsidy.find(`#subsidy-value-${id}`).val(PriceFormatter(price - value))
        rangeSubsidy.find(`#subsidy-${id}`).val(price - value)

        handleInputChange(this)
        OrderDetails.setSubtotal(this, data)
        OrderDetails.changeCardTotal(this, data)
      })
    })
  },
  init(el, translations) {
    this.currentProgram = el
    this.t = translations
    this.defaultState = this.selectedPrograms.length === 0

    return this
  },
  programDetails(data) {
    const t = this.t

    this.append(ProgramHead(data, t))
    this.append(ProgramSubsidy(data, t))
    this.append(ProgramQuantity(data, t))
    this.append(ProgramLimit(data, t))
    this.append(ProgramSubtotal(data, t))
  },
  purchaseLimit(data) {
    const id = data.variant_id
    const checkbox = $(`#limit-checkbox-${id}`)
    const checkboxWrapper = checkbox.closest('[data-limit]').find('.basicProgram_increaseLimit')

    const checkState = () => {
      if (checkbox.is(':checked')) {
        checkboxWrapper.hide()
        $(`#limit-${id}`).val('1')
      } else {
        checkboxWrapper.show()
      }
    }

    checkState()
    checkbox.change(() => checkState())
  },
  render(data) {
    $(this.currentProgram).append('<div class="basicProgram"></div>')
    this.programDetails(data)
    this.handleRangeChange(data)
    this.purchaseLimit(data)
    $(document).ready(() => initTooltip())
  },
  resetData() {
    this.selectedPrograms = []
    this.total = null
    this.currentProgram = null
  },
  setCartTotal() {
    const subscriptionFee = Number(this.t.subscription_fee)
    this.total = this.selectedPrograms.reduce((acc, el) => acc + el.quantity * el.subsidy, 0) + subscriptionFee

    $('#cart-total').empty().append(CartTotal(this.selectedPrograms, this.total, this.t))
  },
  setOrderDetails(event) {
    event.preventDefault()
    this.selectedPrograms.forEach((item) => {
      const el = `#basic-program-${item.variant_id}`
      if ($(el).contents().length === 0) {
        OrderDetails.init(el, this.t).render(item)
        OrderDetails.checkState()
      }

      if ($('.stepSecond').length > 0) {
        $('html,body').animate({ scrollTop: $('#order-details-header').offset().top }, 'slow')
      }
    })
    this.disableSubmit()
  },
  setSubtotal(input, data) {
    const id = data.variant_id
    const quantity = parseInt($(`#quantity-value-${id}`).val() || $(`#initial-quantity-${id}`).val(), 10)
    const subsidy = parseInt($(`#subsidy-${id}`).val(), 10)
    const subtotal = PriceFormatter(subsidy * quantity)

    $(input).closest(`#basic-program-${id}`).find(`#subtotal-${id}`).text(subtotal)
  },
}

$(document).on('input', '.subsidy-input-field-js', function () {
  const inputValue = $(this).val()
  const rangeInput_ = $(this).parents('.range').find('input[type="range"]')
  const subsidyField = $(this).siblings('input')

  if (inputValue === '') {
    return
  }

  subsidyField.val(inputValue.replace(/\D/g, ''))
  $(this).val(`${currency}${inputValue.replace(/\D/g, '')}`)
  rangeInput_.val(this.max - subsidyField.val())
  rangeInput_.trigger('input')
})

window.OrderDetails = OrderDetails
