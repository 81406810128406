import $ from 'jquery'

const ProgramVariant = (variant, t) => {
  return `
    <tr class="cart-item bp-cart-item text-center">
      <td class="bp-cart-item-detail text-start align-items-center d-flex mb-3 mb-md-0">
        <div class="bp-cart-item-image">
          <img src="${variant.image}" class="w-100" alt="${variant.name}">
        </div>
        <div class="cart-item-detail-right">
          <h5 class="green-text fw-medium mb-0">${variant.size}</h5>
          <div class="grey-text">${variant.unit}</div>
        </div>
      </td>
      <td class="fw-bold">
        <span class="bp-head-mobile">${t.subsidy}:</span>
        ${SPREE_CURRENCY_SIGN}${variant.subsidy}
      </td>
      <td class="fw-bold">
        <span class="bp-head-mobile">${t.purchase_limit}:</span>
        ${variant.limit}/${t.day}
      </td>
      <td class="fw-bold">
        <span class="bp-head-mobile">${t.quantity}:</span>
        ${variant.quantity}
      <span class="multiple-icon">X</span>
      </td>
      <td class="fw-bold text-end">
        <span class="bp-head-mobile">${t.price}:</span>
        ${SPREE_CURRENCY_SIGN}${variant.price}
      </td>
    </tr>
  `
}

const Summary = {
  attr: function (attr_name) {
    return `#summary [data-program="${attr_name}"]`
  },
  init: function (program) {
    this.setBrandDetails(program.brand_filled_details)
    this.setName(program.name)
    this.setProductVariants(program.variants, program.translations)
    this.setTotal(program.card_total)
    this.setWasteStream(program.waste_stream)
    this.status = program.status
    this.id = program.id
    this.setLabels(this.status,this.id, program.translations)
  },
  setBrandDetails: function (fields) {
    $(this.attr('brand_details')).empty()
    fields.map(field => $(this.attr('brand_details')).append(`<li class="checkList_item">${field}</li>`))
  },
  setLabels: function (status, id, t) {
    let programId = id
    if (status !== 'live') {
      $('[data-summary-status-dependant]').hide()
    } else {
      $('[data-summary-status-dependant]').show()
    }
    if (status === 'waiting_for_approval') {
      $('.readMoreLink[data-summary-status-dependant]').parent().empty().append(
        `<a href="javascript:void(0);" class="readMoreLink" data-cancel-submission>${t.cancel_submission}</a>`
      )
      $('[data-cancel-submission]').on('click', function (e) {
        e.preventDefault()
        e.stopImmediatePropagation()
        if (window.confirm(t.cancel_submission_confirm)) {
          let formData = new FormData()
          formData.append('state', 'draft')
          formData.append('authenticity_token', $('meta[name=csrf-token]').attr('content'))

          $.ajax({
            cache: false,
            contentType: false,
            data: formData,
            headers: {
              Authorization: SpreeAPI.prepareHeaders().Authorization},
            processData: false,
            success: function() {
              window.location.reload()
            },
            type: 'POST',
            url: `/${SPREE_LOCALE}/self_served_programs/${programId}/change_state`,
          })
        }
        return false
      })
    }
  },
  setName: function (name) {
    $(this.attr('name')).empty().append(name)
  },
  setProductVariants: function (variants, t) {
    $(this.attr('variants')).empty()
    variants.map(variant => $(this.attr('variants')).append(ProgramVariant(variant, t)))
  },
  setTotal: function (total) {
    $(this.attr('total')).empty().append(SPREE_CURRENCY_SIGN + total)
  },
  setWasteStream: function (waste_stream) {
    $(this.attr('waste_stream')).empty().append(waste_stream)
  },
}

window.Summary = Summary
