import $ from 'jquery'

const currency = SPREE_CURRENCY_SIGN

const OrderItem = (item, id) => {
  return `
    <div class="orderItem">
      <div class="orderItem_headline"
           id="orderItem_${id}"
           type="button"
           data-bs-toggle="collapse"
           data-bs-target="#orderItem_collapse_${id}"
           aria-expanded="false"
           aria-controls="orderItem_collapse_${id}"
      >
        <span class="orderItem_name">${item.program_name}</span>
        <span class="orderItem_details">
          <span class="orderItem_date">${item.completed_at}</span>
          <span class="orderItem_fee">${currency}${item.total}</span>
        </span>
      </div>
  
      <div id="orderItem_collapse_${id}" class="collapse">
        <div class="orderItem_variants">
          ${item.order_items.map(el => OrderItemVariants(el)).join('')}
        </div>
      </div>
    </div>
  `
}

const OrderItemVariants = (item) => {
  return `
    <div class="variantsItem">
      <span class="variantsItem_name">${item.name}</span>
      <div class="text-nowrap">
        ${item.quantity !== null ? `<span>${item.quantity} X </span>` : ''}
        <span>${currency}${item.price}</span>
      </div>
    </div>
  `
}

const OrderHistory = {
  orders: [],
  init: function (orders) {
    this.orders = orders
    this.render()

    return this
  },
  render: function () {
    const container = $('#orderHistory [data-type="orders"]')

    container.empty()
    this.orders.map((item, id) => container.append(OrderItem(item, id)))
  },
}

window.OrderHistory = OrderHistory
