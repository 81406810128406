import $ from 'jquery'

const cancelSubscriptionView = (program, t) => {
  return `
    <h2 class="text-center mb-3">${t.title_canceled}</h2>
    <h5 class="text-center">${program.program_name}</h5>
    <br>
    <p class="mediumText grey-text">${t.description_canceled}</p>
    <div class="text-center">
      <a href="${t.contact_us_url}" class="readMoreLink">${t.contact_us_label}</a>
    </div>
  `
}

const cancelSubscription = {
  program: null,
  t: null,
  cancel: function (t) {
    this.t = t
    $.ajax({
      data: { authenticity_token: $('meta[name=csrf-token]').attr('content')},
      dataType: 'json',
      type: 'PATCH',
      url: `/subscriptions/${this.program.subscription_id}/cancel`,
      success: function() {
        let cancelSubscriptionPopup = document.getElementById('cancelSubscription')

        $('.cancelSubscription__content').empty().append(cancelSubscriptionView(cancelSubscription.program, cancelSubscription.t))
        cancelSubscriptionPopup.addEventListener('hidden.bs.modal', () => window.location.reload())
      },
    })
  },
  init: function (program) {
    this.program = program
    this.setName(program.program_name)
    this.setStock(program.stock_items)

    return this
  },
  setName: function (name) {
    $('[data-program="name"]').empty().append(name)
  },
  setStock: function (stock_items) {
    $('[data-program="stock"]').empty()
    stock_items.map(item => $('[data-program="stock"]').append(`<div>${item.count} ${item.name}</div>`))
  },
}

window.cancelSubscription = cancelSubscription
