const OrderForm = {
  // Returns a selector string for a given attribute name
  attr: function (attrName) {
    return `#orderForm [data-program="${ attrName }"]`
  },

  // Updates the HTML content for a variant attribute
  attrVariant: function (id, value, html) {
    $(`#orderForm [data-variant-${ id }="${ value }"]`).empty().append(html)
  },

  // Handles the checkbox functionality for variants
  handleCheckbox: function (el, id, variant, checkboxHtml) {
    $(`#orderForm [data-variant-${ id }="checkbox"]`).append(checkboxHtml)
    const checkbox = el.find('input[type=checkbox]')
    variant.available ? checkbox.attr('checked', variant.checked) : checkbox.attr('disabled', !variant.available)
  },

  // Handles different variant attributes based on the key
  handleVariant: function (key, value, id, variant, el, checkboxHtml) {
    switch (key) {
    case 'available':
      this.setAvailability(el, value, variant)
      break
    case 'image':
      this.attrVariant(id, key, `<img class="responsiveImage_img" src="${ value }" alt="${ variant.size }" />`)
      break
    case 'size':
      this.attrVariant(id, key, `<b>${ value }</b>`)
      break
    case 'price':
      this.attrVariant(id, key, value !== null ? `${ SPREE_CURRENCY_SIGN }${ value }` : '-')
      break
    case 'unit':
      this.attrVariant(id, key, value !== null ? value : '-')
      break
    case 'recommended':
      value.forEach(item => this.attrVariant(id, key, `<li>${ item }</li>`))
      break
    case 'checked':
      this.handleCheckbox(el, id, variant, checkboxHtml)
      break
    default:
      break
    }
  },

  id: null,

  // Initializes the form with order details
  init: function (order, id) {
    this.id = id
    this.setName(order.name)
    this.setVariants(order.variants)
    this.setVariantsDetail(order)
    this.setId()
  },

  // Sets the form action attribute
  setAvailability: function (el, value, variant) {
    el.removeClass('notAvailable checked')
    if (!value) el.addClass('notAvailable')
    if (variant.checked) el.addClass('checked')
  },

  // Sets the name in the form
  setId: function () {
    $('#order-form').attr('action', `/self_served_programs/${ this.id }/restocking_programs`)
  },

  // Sets the availability based on the value
  setName: function (name) {
    $(this.attr('name')).empty().append(name)
  },

  // Processes and displays each variant in the form
  setVariants: function (variants) {
    variants.forEach((variant, id) => {
      const el = $(`#orderForm [data-variant-${ id }="item"]`)
      const checkboxHtml = `<input type="checkbox" data-variant-id="${ variant.variant_id }" 
                                    id="program-${ variant.variant_id }" />`

      Object.entries(variant).forEach(([key, value]) => {
        this.handleVariant(key, value, id, variant, el, checkboxHtml)
      })
    })
  },

  // Sets detailed information for each variant
  setVariantsDetail: function (order) {
    order.variants.forEach(variant => {
      if (variant.available) {
        $('.orderDetails_programs').append(`<div id="basic-program-${ variant.variant_id }"></div>`)
        OrderDetails.init(`#basic-program-${ variant.variant_id }`, order.translations).checkStatus(variant)
      }
    })
    OrderDetails.checkState()
    OrderDetails.setCartTotal()
    $('#budget').val(order.budget)
  },
}

$(document).ready(function() {
  if ($('#orderForm').length > 0) {
    document.getElementById('orderForm').addEventListener('hidden.bs.modal', () => {
      $('.selfPrograms_select input[type=checkbox]').remove()
      $('.orderDetails_programs').empty()
      OrderDetails.resetData()
    })

    $('#order-form').on('submit', handleFormSubmit)
  }
})

// Handles form submission with AJAX
function handleFormSubmit(event) {
  event.preventDefault()
  const formData = $(this).serialize()

  $.ajax({
    data: formData,
    error: handleErrorResponse,
    success: handleSuccessResponse,
    type: 'POST',
    url: $(this).attr('action'),
  })
}

// Handles the response on successful AJAX request
function handleSuccessResponse() {
  $('#orderForm').modal('hide')
  resetErrorMessages()
  showSuccessMessages()
}

// Handles the response on failed AJAX request
function handleErrorResponse(jqXHR) {
  $('#orderForm').modal('hide')
  resetSuccessMessages()
  const errorMessage = jqXHR.responseJSON && jqXHR.responseJSON.message
    ? jqXHR.responseJSON.message
    : 'There was an error processing your request. Please try again.'
  showError(errorMessage)
}

// Resets error messages in the modal
function resetErrorMessages() {
  $('#restockModal #restockErrorMessage').hide()
}

// Resets success messages in the modal
function resetSuccessMessages() {
  $('#restockModal .success-header').hide()
  $('#restockModal .success-subheader').hide()
  $('#restockModal #restockSuccessMessage').hide()
}

// Shows success messages in the modal
function showSuccessMessages() {
  $('#restockModal .success-header').show()
  $('#restockModal .success-subheader').show()
  $('#restockModal #restockSuccessMessage').show()
  $('#restockModal').modal('show')
}

// Displays error message in the modal
function showError(message) {
  resetSuccessMessages()
  $('#restockModal #restockErrorMessage').text(message).show()
  $('#restockModal').modal('show')
}

window.OrderForm = OrderForm
