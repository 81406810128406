window.addEventListener('DOMContentLoaded', () => {
  const costItem = $('.cost-item')
  function setActiveCost() {
    $('#costs').find('.label-wrapper').text($('.cost-item.active:last').find('span:first').text())
  }
  if(costItem.hasClass('active')) {
    setActiveCost()
    $('#costFilterAppliedBadge').text($('.cost-item.active').find('span:first').text())
  }

  costItem.click(function(e) {
    e.stopPropagation()
    $('.cost-item').removeClass('active')
    $(this).addClass('active')
    $('#selectedCost').val($(this).attr('data-id'))
    setActiveCost()
    $('#filter_cost').collapse('hide')
  })
})
