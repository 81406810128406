const initTooltip = () => {
  $('[data-bs-toggle="tooltip"]').tooltip({
    customClass: 'tooltip-wrapp',
    fallbackPlacements: ['bottom'],
    html: true,
    placement: 'bottom',
  })
}

$(document).ready(function() {
  initTooltip()
})

window.initTooltip = initTooltip
