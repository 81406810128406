import $ from 'jquery'

$(document).ready(function() {
  $('.saveAndClose').click(function (e) {
    e.preventDefault()

    $('#selfServedForm').append('<input type="hidden" name="save_and_close" value="" />')
    $('#selfServedForm').submit()
  })

  if ($('#selfServedForm').length) {
    const checkBoxes = document.querySelectorAll('#selfServedForm input[type="checkbox"]:required'),
      submitButton = document.querySelectorAll('#selfServedForm button[data-type="submit"]')

    const checkButtonStatus = () => {
      const checkedCount = [...checkBoxes].filter(cb => cb.checked)
      if (checkBoxes.length > 0) {
        submitButton.forEach(button => button.disabled = checkedCount.length !== checkBoxes.length)
      }
    }

    checkBoxes.forEach(cb => cb.addEventListener('change', checkButtonStatus))

    checkButtonStatus()
  }
})
