import $ from 'jquery'

$(document).on('click', '.js-videoPoster', function (e) {
  e.preventDefault()
  const poster = $(this)
  const wrapper = poster.parent('.js-video')
  videoPlay(wrapper)
})

function videoPlay(wrapper) {
  const iframe = wrapper.find('.js-videoIframe')
  const src = iframe.attr('src')
  wrapper.addClass('videoActive')
  iframe.attr('src', `${src}&autoplay=1`)
}
