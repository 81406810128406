$(document).ready(function() {
  // Render item to dashboard
  const DashboardItem = (item) => `
    <tr class="collapse__card-purchases-row">
      <td class="collapse__card-purchases-label">${item.variant_name}</td>
      <td class="collapse__card-purchases-value text-end">${item.percentage}%</td>
    </tr>`

  // Send request and handle the response
  const request = (id, time, table) => {
    $('.loader').show() // Show loader

    const headers = SpreeAPI.prepareHeaders()

    $.ajax({
      error: function (jqXHR, textStatus, errorThrown) {
        console.error(`Request failed: ${ textStatus }, ${ errorThrown }`)
        $('.loader').hide() // Hide loader
      },
      headers: headers,
      success: function (data) {
        table.empty()
        data.variants.map((item) => table.append(DashboardItem(item)))
        $('.loader').hide() // Hide loader
      },
      type: 'get',
      url: `/api/v2/storefront/self_served_programs/${ id }/kpis/purchases?filter=${ time }`,
    })
  }

  // Event listeners for collapse shown and dashboard select change
  $('.has-dashboard').on('shown.bs.collapse', function () {
    const element = $(this)
    const id = element.data('dashboard-program-id')
    const table = element.find('[data-dashboard-table]')
    const select = element.find('[data-dashboard-select]')
    const selectedValue = select.val()

    request(id, selectedValue, table)
  })

  $('[data-dashboard-select]').on('change', function () {
    const element = $(this).parents('[data-dashboard-program-id]')
    const id = element.data('dashboard-program-id')
    const table = element.find('[data-dashboard-table]')

    request(id, $(this).val(), table)
  })

  // Check for token availability
  let checkTokenInterval = setInterval(function() {
    if (SpreeAPI.oauthToken) {
      clearInterval(checkTokenInterval)
      $('#nav-tabContent-basic-programs .has-dashboard.collapse').collapse('show')
    }
  }, 100)
})
