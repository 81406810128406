$(document).ready(function() {
  const fileDetails = (event, file) => {
    return `
      <div class="fileAttach_item">
        <div class="fileAttach_image">
          <img src="${event.target.result}" 
          title="${file.name}" 
          alt="${file.name}"/>
        </div>
        <div class="fileAttach_details">
          <div class="fileAttach_name">${file.name}</div>
          <div class="fileAttach_size">${toNaturalFileSize(file.size)}</div>
          <button type="button" class="btn btn-link remove" data-remove-image>
            <span class="fas fa-trash"></span>
          </button>
        </div>
      </div>
  `
  }

  // File size calculations, can set to 1024, this is only used for display, properties use file size base 1000
  const toNaturalFileSize = (bytes) => {
    const base = 1000,
      KB = base,
      MB = base * base,
      GB = base * base * base

    bytes = Math.round(Math.abs(bytes))

    // just bytes
    if (bytes < KB) {
      return `${bytes} bytes`
    }

    // kilobytes
    if (bytes < MB) {
      return `${Math.floor(bytes / KB)} KB`
    }

    // megabytes
    if (bytes < GB) {
      return `${removeDecimalsWhenZero(bytes / MB, 1, '.')} MB`
    }
  }

  const removeDecimalsWhenZero = (value, decimalCount, separator) => {
    return value
      .toFixed(decimalCount)
      .split('.')
      .filter(part => part !== '0')
      .join(separator)
  }

  const renderDetails = (input) => {
    let files = input.files,
      filesLength = files.length

    for (let i = 0; i < filesLength; i++) {
      let f = files[i],
        fileReader = new FileReader()

      fileReader.onload = (function(e) {
        $(input).siblings('.fileAttach_images').append(fileDetails(e, f))
      })
      fileReader.readAsDataURL(f)
    }
  }

  $(document).on('click', '[data-remove-image]', function () {
    let id = $(this).closest('.fileAttach_item').data('signed-id'),
      input = $('[data-images-to-remove]'),
      inputImage = $(this).closest('.fileAttach').find('input[type="file"]')[0],
      temp = []
    if (inputImage.files.length > 0) {
      inputImage.value = ''
      if(!/safari/i.test(navigator.userAgent)) {
        inputImage.type = ''
        inputImage.type = 'file'
      }
    }
    if (input.val()) {
      temp.push(JSON.parse(input.val()))
      temp.toString().split(',')
    }
    $(this).closest('.fileAttach_item').remove()
    temp.push(id)
    input.val(JSON.stringify(temp.toString().split(',')))
  })

  const removeAllPreview = (input) => {
    $(input).siblings('.fileAttach_images').empty()
  }

  if (window.File && window.FileList && window.FileReader) {
    $('.fileAttach input').on('change', function() {
      let maxAttachedFiles = this.dataset.maxFiles,
        filesLength = this.files.length

      if (maxAttachedFiles >= filesLength) {
        $(this).parent().find('.error').remove()
        removeAllPreview(this)
        renderDetails(this)
      } else {
        removeAllPreview(this)
        this.value = null
        $(this).parent().append(`<span class="error">${maxAttachedFiles} image can be uploaded</span>`)
      }
    })
  } else {
    alert('Your browser doesn\'t support to File API')
  }
})
