import $ from 'jquery'
import 'slick-carousel'
import { debounce } from 'lodash'

const sliderSettings = data => {
  if (window.isProductSelected) {
    $('.stepOne_bottomSliderContent, .stepOne_topSlider').on('init', function (event, slick) {
      $('.stepOne_topSlider').on('afterChange',
        () => setProductId($('.slick-current .stepOne_topSliderItem').data('id'), false))
      setTimeout(function () {
        setSelected($('#originalProductId').val())
        setProductId(window.selectedProductId, false)
      }, 50)
    })
  }

  $('.stepOne_topSlider').slick({
    asNavFor: '.stepOne_bottomSliderContent',
    centerMode: true,
    centerPadding: 0,
    focusOnSelect: true,
    infinite: data.length > 5,
    responsive: [{
      breakpoint: 995,
      settings: {
        infinite: data.length > 3,
      },
    }],
    variableWidth: true,
  })

  $('.stepOne_bottomSliderContent').slick({
    arrows: false,
    asNavFor: '.stepOne_topSlider',
    cssEase: 'linear',
    fade: true,
    infinite: data.length > 5,
  })
}

const topSliderItems = el => {
  return `
    <div class="stepOne_topSliderItem" data-id="${el.id}">
      <div class="stepOne_topSliderImage"><img src="${el.image}" alt="${el.name}"></div>
      <div class="stepOne_topSliderText"><span>${el.name}</span></div>
    </div>
  `
}

const wastesList = (el) => {
  let recyclable = el.recyclable_taxons,
    nonRecyclable = el.non_recyclable_taxons,
    wastesLength = recyclable.length + nonRecyclable.length,
    showItemNumber = $(window).width() > 992 ? 12 : 6

  return `
  <div data-role="list">
    <ul class="wastesList">
      ${recyclable.map((item) => `<li class="checkList_item smallText action_item">${item.name}</li>`).join('')}
      ${nonRecyclable.map((item) => `<li class="timesList_item smallText action_item">${item.name}</li>`).join('')}
    </ul>
      ${wastesLength > showItemNumber ? '<div class="stepOne_bottomSliderViewAll"><a href="javascript:void(0);" onclick="viewAll(event)">View all</a></div>' : ''}
  </div>
  `
}

const setProductId = (id, isDisabled = true) => {
  let submitButton = document.querySelectorAll('#selfServedForm button[data-type="submit"]')
  let searchTerm = $('#stepOne_searchInput').val()
  $('#originalProductId').val(id)
  $('#lastSearchTerm').val(searchTerm)
  submitButton.forEach(button => button.disabled = isDisabled)
}

const showWastes = (isShow = true) => {
  isShow ? $('.withData').show() : $('.withData').hide()
  isShow ? $('.stepOne_topSliderContainer').show() : $('.stepOne_topSliderContainer').hide()
}

const defaultPageState = (result, isDefault = true) => {
  let $productId = $('.slick-current .stepOne_topSliderItem').data('id')

  isDefault ? $('.stepOne_sliderContainer').hide() : $('.stepOne_sliderContainer').show()
  isDefault ? $('.stepOne_viewAll').show() : $('.stepOne_viewAll').hide()
  result ? setProductId($productId, false) : setProductId('')
}

const requestData = (search, viewAll) => {
  let searchTerm

  if (viewAll) searchTerm = ''
  else if (search.val().length >= 2) searchTerm = search.val()
  else if ($('#lastSearchTerm').val().length >= 2) searchTerm = $('#lastSearchTerm').val()
  else {
    showWastes(false)
    defaultPageState(false, false)
    $('.loader').hide()
    search.removeAttr('disabled').focus()
  }

  $.ajax({
    dataType: 'json',
    type: 'GET',
    url: `/${SPREE_LOCALE}/self_served_programs/search_products?query=${searchTerm}`,
    success: function(data) {
      if (data.length > 0) {
        $('.stepOne_topSlider').hasClass('slick-initialized') && $('.stepOne_topSlider').slick('unslick').empty()
        $('.stepOne_bottomSliderContent').hasClass('slick-initialized') && $('.stepOne_bottomSliderContent').slick('unslick').empty()

        $.each(data, (_, el) => {
          $('.stepOne_topSlider').append(topSliderItems(el))
          $('.stepOne_bottomSliderContent').append(wastesList(el))
        })

        showWastes()
        sliderSettings(data)
      } else {
        showWastes(false)
      }

      defaultPageState(data.length > 0, false)
      $('.loader').hide()
      search.removeAttr('disabled').focus()
    }})
}

const setSelected = (id) => {
  let slideno = $(`.stepOne_topSliderItem[data-id=${id}]`).closest('.slick-slide').data('slick-index')
  $('.stepOne_topSlider').slick('slickGoTo', slideno, true)
}

const drawItem = (viewAll = false) => {
  const $search = $('#stepOne_searchInput')

  if ((($search.val() === '') || ($search.val().length <= 2)) && !viewAll) {
    defaultPageState(false,true)
  } else {
    $search.prop('disabled', true)
    $('.loader').show()
    requestData($search, viewAll)
  }
}

$(document).ready(function() {
  const debouncedDraw = debounce(drawItem, 300)
  let productIdElement = $('#originalProductId')

  if (productIdElement.val() !== undefined) {
    window.isProductSelected = productIdElement.val().length !== 0
    window.selectedProductId = productIdElement.val()
  }

  $('#stepOne_searchInput').on('input', () => debouncedDraw())
  $('#stepOne_searchInput').on('keypress', (e) => e.which !== 13)

  if (window.isProductSelected) {
    drawItem(true)
  } else {
    $('.stepOne_topSlider').on('afterChange',
      () => setProductId($('.slick-current .stepOne_topSliderItem').data('id'), false))
  }

  $('.viewAllRecycles').on('click', () => drawItem(true))
})
