window.addEventListener('DOMContentLoaded', () => {
  const categoryItem = $('.category-item')
  function setActiveTaxon() {
    $('#taxonomies').find('.label-wrapper').text($('.category-item.active').find('span:first').text())
  }
  if(categoryItem.hasClass('active')) {
    setActiveTaxon()
  }

  categoryItem.click(function(e) {
    e.stopPropagation()
    $('.category-item').removeClass('active')
    $(this).addClass('active')
    $('#selectedTaxonomy').val($(this).attr('data-id'))
    setActiveTaxon()
    $('#filter_category').collapse('hide')
  })
})

